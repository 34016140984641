export const apiEndPoints = {
  suggestionsHomePage: {
    url: "suggestion",
    isMock: false,
  },
  dashboard: {
    url: "cdp/campaign/target/",
    isMock: false,
  },
  dashboardDetail: {
    url: "cdp/campaign/target/",
    isMock: false,
  },
  campaignList: {
    url: "cdp/campaign/target/unique-ids",
    isMock: false,
  },
  getTargetDetails: {
    url: "cdp/campaign/target/",
    isMock: false,
  },
  targetHomePage: {
    url: "cdp/campaign/target",
    isMock: false,
  },
  simulatorResult: {
    url: "cdp/campaign/simulator/async",
    isMock: false,
  },
  barChartData: {
    url: "mocks/barChart.json",
    isMock: true,
  },
  profileFilterOptions: {
    url: "cdp/master-data/filters/level1",
    isMock: false,
  },
  levelTwoFilterOptions: {
    url: "cdp/master-data/filters/level2",
    isMock: false,
  },
  levelThreeFilterOptions: {
    url: "cdp/master-data/filters/level3",
    isMock: false,
  },
  applyFilters: {
    url: "targetstotal",
    isMock: false,
  },
  campaignTarget: {
    url: "cdp/campaign/target/async",
    isMock: false,
    isTest: true,
  },
  pushTargetsLocal: {
    url: "targetsdetails/local",
    isMock: false,
  },
  cdpAuthenticator: {
    url: "token",
    isMock: false,
  },
  brands: {
    url: "cdp/campaign/simulator/brands",
    isMock: false,
  },
  channels: {
    url: "mocks/channels.json",
    isMock: true,
  },
  saleslines: {
    url: "cdp/campaign/simulator/saleslines",
    isMock: false,
  },
  refreshTarget: {
    url: "cdp/campaign/target/refresh",
    isMock: false,
  },
  updateSavedTargets: {
    url: "cdp/campaign/target",
    isMock: false,
  },
  pushTargetsSfmc: {
    url: "targetsdetails/sfmc",
    isMock: false,
  },
  mapping: {
    url: "mocks/mapping.json",
    isMock: true,
  },
  getUserDetails: {
    url: "user/loggedInUserProfile/ui",
    isMock: false,
  },
  campaignOverview: {
    url: "reports/campaignTarget",
    isMock: false,
  },
  columnChartData: {
    url: "mocks/columnChart.json",
    isMock: true,
  },
  donutChartData: {
    url: "mocks/donutChart.json",
    isMock: true,
  },
  accChartData: {
    url: "mocks/accChart.json",
    isMock: true,
  },
  updateSuggestionStatus: {
    url: "suggestion",
    isMock: false,
  },
  getSuggestionTargetDetails: {
    url: "cdp/campaign/suggestion/export/target/async",
    isMock: false,
  },
  getSuggestionShipmentDetails: {
    url: "cdp/campaign/suggestion/export/shipment/async",
    isMock: false,
  },

  getUserSuggestions: {
    url: "suggestion/user-suggestions",
    isMock: false,
  },
  closeSuggestion: {
    url: "suggestion/close",
    isMock: false,
  },
  saveToken: {
    url: "user/token",
    isMock: false,
  },
  deleteDashboradItem: {
    url: "cdp/campaign/target/",
    isMock: false,
  },
  getSimulateDetails: {
    url: "cdp/campaign/simulator/",
    isMock: false,
  },
  downloadTemplate: {
    url: "cdp/master-data/template/",
    isMock: false,
  },
  saveSimulation: {
    url: "cdp/campaign/simulator/save/",
    isMock: false,
  },
  getSenderDetails: {
    url: "cdp/master-data/",
    isMock: false,
  },
  getNotificationDetails: {
    url: "cdp/campaign/notification",
    isMock: false,
  },
  updateNotificationDetails: {
    url: "cdp/campaign/notification",
    isMock: false,
  },
  targetExcelUpload: {
    url: "cdp/campaign/target/convert-target-file",
    isMock: false,
  },
  sendSFMC: {
    url: "cdp/campaign/shipment/sfmc/async",
    isMock: false,
  },
  shipmentDownloadXLSX: {
    url: "cdp/campaign/shipment/details/local/async",
    isMock: false,
  },
  simulatorDownloadXLSX: {
    url: "cdp/campaign/simulator/details/local/async",
    isMock: false,
  },
  fileUploadCheck: {
    url: "cdp/campaign/shipment/local-file/validate",
    isMock: false,
  },
  draftCampaign: {
    url: "cdp/campaign/target/draft-campaign",
    isMock: false,
  },
  getImpactInfo: {
    url: "cdp/campaign/impact/async",
    isMock: false,
  },
  impactData: {
    url: "mocks/impact.json",
    isMock: true,
  },
  getImpactTouchPoints: {
    url: "cdp/campaign/impact/touchpoint/async",
    isMock: false,
  },
  homeSfmcUpload: {
    url: "cdp/campaign/target/homeupload/async",
    isMock: false,
  },
  surveyFilterOptions: {
    url: "cdp/master-data/survey",
    isMock: false,
  },
  targetV2Filters: {
    // url: "filters",
    url: "cdp/campaign/target-v2/filters/async",
    isMock: false,
  },
  targetV2FilterValues: {
    url: "cdp/campaign/target-v2/filtersvalues/async",
    isMock: false,
  },
  targetV2Volumes: {
    url: "cdp/campaign/target-v2/volumes/async",
    isMock: false,
  },
  targetV2Create: {
    url: "cdp/campaign/target-v2/create/async",
    isMock: false,
  },
  targetV2DownloadTemplate: {
    url: "cdp/campaign/target-v2/download/async",
    isMock: false,
  },
  targetV2ModifyTL: {
    url: "cdp/campaign/target-v2/modify/async",
    isMock: false,
  },
  purpose: {
    url: "cdp/master-data/purposes",
    isMock: false,
  },
  targetV2SaveTarget: {
    url: "cdp/campaign/target/save-targetv2",
    isMock: false,
  },
  appsData: {
    url: "cdp/campaign/home/appsData/async",
    isMock: false,
  },
  recentUpdates: {
    url: "cdp/campaign/home/recentUpdates/async",
    isMock: false,
  },
};
