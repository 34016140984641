import { useEffect, useState } from "react";
import { Grid, Box } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useRecoilValue } from "recoil";
import { userUploadedCSVState } from "recoil/atoms";
import { Button } from "components/shared/Button";
import { TablePagination } from "components/shared/TablePagination";
import { ImageIcon } from "components/shared/ImageIcon";
import { useTranslation } from "react-i18next";

const CustomPreview = (props:any) => {
    const { t } = useTranslation();
    const userUploadedData = useRecoilValue(userUploadedCSVState);
    const [isBayerCrmIdPresent, setIsBayerCrmIdPresent] = useState(true);
    const [totalCount, setTotalCount] = useState<any>(0);
    const [pageNumber, setPageNumber] = useState<any>(1);
    const [currentPageCount, setCurrentPageCount] = useState<any>(0);
    const [totalPages, setTotalPages] = useState<any>(0);
    const [currentData, setCurrentData] = useState<any>([]);
    const [sanitizedData, setSanitizedData] = useState<any>([]);
    const [errorCount, setErrorCount] = useState<any>(0);
    const [errorArray, setErrorArray] = useState<any>([]);
    const size = 5;
    const uploadTableDataSetter = (data: any) => {
        //let parsedData = [...userUploadedData];
        let parsedData = data;
        if (parsedData && Array.isArray(parsedData) && parsedData.length > 1) {
          let dataCount = parsedData.length - 1;
          setTotalCount(dataCount);
          setTotalPages(Math.ceil(dataCount / size));
          if (dataCount > size) {
            setCurrentPageCount(5);
            let bodyTable = parsedData.slice(1, 6);
            setCurrentData(bodyTable);
          } else {
            let bodyTable = parsedData.slice(1);
            setCurrentData(bodyTable);
            setCurrentPageCount(dataCount);
          }
        }
      };
    const cleanseData = () => {
        let parsedData = [...userUploadedData];
        if (parsedData && Array.isArray(parsedData) && parsedData.length > 1) {
          const headerData = parsedData[0];
          const isIdPresent = headerData[0] === "BayerCRM_IDValue";
          if (isIdPresent) {
            //proceed for other validation
            let dataBody = parsedData.slice(1);
            console.log("dataBody", dataBody);
            let pattern = /^([a-zA-Z0-9]){18}$/;
            let dummyErrorArray: any[] = [];
            dataBody.forEach((item: any) => {
              if (item.length > 0) {
                const text = item[0];
                const isValid = pattern.test(text);
                if (isValid) {
                  dummyErrorArray = [...dummyErrorArray, false];
                } else {
                  dummyErrorArray = [...dummyErrorArray, true];
                }
              } else {
                dummyErrorArray = [...dummyErrorArray, true];
              }
            });
            setErrorArray(dummyErrorArray);
            // error management
            if (dummyErrorArray.includes(true)) {
              console.log("err present");
              //rearrage the error
              let newErrorArray: any = [];
              let newDataArray: any = [];
              dummyErrorArray.forEach((item: any, index: number) => {
                if (item === true) {
                  newErrorArray = [item, ...newErrorArray];
                  newDataArray = [dataBody[index], ...newDataArray];
                } else {
                  newErrorArray = [...newErrorArray, item];
                  newDataArray = [...newDataArray, dataBody[index]];
                }
              });
              console.log("newErrorArray", newErrorArray);
              console.log("newDataArray", newDataArray);
              // number of errors
              const errors = newErrorArray.filter((item: any) => item === true);
              const errorCount = errors.length;
              const errorsCollection: any = newDataArray.slice(0, errorCount);
              const noErrorsCollection: any = newDataArray.slice(errorCount);
              const reversedArray = errorsCollection.reverse();
              const refinedData = reversedArray.concat(noErrorsCollection);
              console.log("errorsCollection", errorsCollection);
              console.log("noErrorsCollection", noErrorsCollection);
              setErrorArray(newErrorArray);
              setErrorCount(errorsCollection.length);
              setSanitizedData([headerData, ...refinedData]);
              uploadTableDataSetter([headerData, ...refinedData]);
            } else {
              console.log("noerrir");
              let fitData = [...userUploadedData];
              setErrorArray([]);
              setErrorCount(0);
              setSanitizedData(fitData);
              uploadTableDataSetter(fitData);
            }
          } else {
            setIsBayerCrmIdPresent(false);
          }
        }
      };
    useEffect(() => {
        console.log("userUploadedData", userUploadedData);
        cleanseData();
      }, [userUploadedData]);
    useEffect(() => {
        console.log("error", errorArray);
      }, [errorArray]);
    const prevIconClick = () => {
        let newPage = pageNumber - 1;
        let parsedData = [...sanitizedData];
        console.log("parsedData",parsedData);
        let start = size * newPage - 4;
        let end = size * newPage + 1;
        let bodyTable = parsedData.slice(start, end);
        setCurrentData(bodyTable);
        setCurrentPageCount(size);
        setPageNumber(newPage);
      };
      const nextIconClick = () => {
        let newPage = pageNumber + 1;
        let parsedData = [...sanitizedData];
        console.log("parsedData",parsedData);
        let start = size * pageNumber + 1;
        let end = size * newPage + 1;
        let bodyTable = parsedData.slice(start, end);
        setCurrentData(bodyTable);
        if (bodyTable && Array.isArray(bodyTable) && bodyTable.length > 1) {
          setCurrentPageCount(bodyTable.length);
        }
        setPageNumber(newPage);
      };
      const getTableHeader = () => {
        let headerArray;
        headerArray = [...userUploadedData[0]];
        const rows =
          Array.isArray(headerArray) && headerArray.length > 0
            ? headerArray.map((item: any, index: number) => {
                if (index === 0) {
                  return (
                    <TableCell key={index}>
                      <>
                        <Grid className="table-name">{item}</Grid>
                        <Grid className="help-text">
                          <Grid className="mandatory">{t("mandatory")}</Grid>
                          {errorCount > 0 && (
                            <Grid className="alert">
                              <Box className="error-count">{errorCount}</Box>
                              <Box className="img-container">
                                <ImageIcon classes="img-alert-no-fill"></ImageIcon>
                              </Box>
                            </Grid>
                          )}
                        </Grid>
                      </>
                    </TableCell>
                  );
                } else {
                  return <TableCell key={index}>{item}</TableCell>;
                }
              })
            : null;
        return rows;
      };
      const getTableBody = () => {
        console.log("currentData",currentData);
        const rows =
          currentData && Array.isArray(currentData) && currentData.length > 0
            ? currentData.map((item: any, index: number) => {
                return (
                  <TableRow key={index}>
                    {item.map((data: any, itemIndex: number) => {
                      if (itemIndex === 0) {
                        if (errorArray[(pageNumber - 1) * size + index] === true) {
                          return (
                            <TableCell key={itemIndex}>
                              <Grid className="body-error">
                                <Box className="img-alert-container">
                                  <ImageIcon classes="img-alert-no-fill"></ImageIcon>
                                </Box>
                                <Box className="error-data">{data}</Box>
                              </Grid>
                            </TableCell>
                          );
                        } else {
                          return <TableCell key={itemIndex}>{data}</TableCell>;
                        }
                      } else {
                        return <TableCell key={itemIndex}>{data}</TableCell>;
                      }
                    })}
                  </TableRow>
                );
              })
            : null;
        return rows;
      };
      const proceedToUpload=()=>{
        //pass data to API
        console.log("data ready to upload");
        // after passing the data & on successful upload show the success msg
        if(props.onSuccess){
            props.onSuccess();
        }
      }
      const retryUpload=()=>{
        if(props.onRetry){
            props.onRetry();
        }
      }
    return(<> 
        {userUploadedData &&
          Array.isArray(userUploadedData) &&
          userUploadedData.length > 1 &&
          isBayerCrmIdPresent && (
            <>
              <Grid item xs={12} className="top-grid custom">
                <Box className="hcps">{`${totalCount}`} HCPs</Box>
                <Box className="btns-wrapper">
                  <div className="pagination-wrapper">
                    <TablePagination
                      pageNumber={pageNumber}
                      size={size}
                      totalCount={totalCount}
                      currentPageCount={currentPageCount}
                      totalPages={totalPages}
                      prevIconClick={prevIconClick}
                      nextIconClick={nextIconClick}
                    />
                  </div>
                </Box>
              </Grid>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>{getTableHeader()}</TableRow>
                  </TableHead>
                  <TableBody>{getTableBody()}</TableBody>
                </Table>
              </TableContainer>
            </>
          )}
        {!(
          userUploadedData &&
          Array.isArray(userUploadedData) &&
          userUploadedData.length > 1
        ) && <Box className="no-data-preview">{t("no_data_available")}</Box>}
        {userUploadedData &&
          Array.isArray(userUploadedData) &&
          userUploadedData.length > 1 &&
          !isBayerCrmIdPresent && (
            <Box className="no-data-preview">
             {t("BayerCRM_IDValue_mandatory")}
            </Box>
          )}
        <Grid
          className={`popup-footer-container ${
            !(
              userUploadedData &&
              Array.isArray(userUploadedData) &&
              userUploadedData.length > 1
            )
              ? "no-items"
              : ""
          }`}
        >
          {userUploadedData &&
            Array.isArray(userUploadedData) &&
            userUploadedData.length > 1 && (
              <Box
                display="flex"
                justifyContent="flex-end"
                gap="25px"
                alignItems="center"
                onClick={errorCount > 0  ?  retryUpload: proceedToUpload}
              >
                <Button type="small" variant="secondary">
                {errorCount > 0 ? "Retry" :"Add HCPs"}
                </Button>
              </Box>
            )}
        </Grid>
</>);
}
export default CustomPreview;