import { useEffect, useState } from "react";
import Filter from "./filter/Filter";
import AddIcon from "@mui/icons-material/Add";
import { Grid } from "@mui/material";
import { Popup } from "components/shared/Popup";
import { CustomFileDropzone } from "../../../../components/shared/FileDropzone/CustomFileDropzone";
import { useTranslation } from "react-i18next";
import CustomPreview from "../customPreview/customPreview";
import { ImageIcon } from "components/shared/ImageIcon";

const CustomFiltersContainer = (props: any) => {
  const { t } = useTranslation();
  const [openPopup, setOpenPopup] = useState(false);
  const [openUploadPopup, setOpenUploadPopup] = useState(false);
  const [openPreviewPopup, setOpenPreviewPopup] = useState(false);
  const [openSuccessPopup, setOpenSuccessPopup] = useState(false);
  const [customUrl, setCustomUrl] = useState("");

  useEffect(() => {
    if (props.targetListCsvProcessingUrl) {
      const segements = props.targetListCsvProcessingUrl
        .split("/")
        .filter((segment: any) => segment);
      const lastSegment = segements[segements.length - 1];
      setCustomUrl(lastSegment);
    }
  }, [props.targetListCsvProcessingUrl]);

  const openIframePopup = () => {
    customUrl ? setOpenPopup(true) : setOpenPopup(false);
  };
  const openAddHcpPopup = () => {
    setOpenUploadPopup(true);
  };
  const closeIframePopup = () => {
    setOpenPopup(false);
  };
  const openPreviewUserPopup = () => {
    setOpenPreviewPopup(true);
  };
  const closePreviewUserPopup = () => {
    setOpenPreviewPopup(false);
  };
  const closeUploadPopup = () => {
    setOpenUploadPopup(false);
  };

  const openSuccess = () => {
    setOpenSuccessPopup(true);
  };
  const closeSuccess = () => {
    setOpenSuccessPopup(false);
  };
  const ValidateFile = () => {
    setOpenUploadPopup(false);
    openPreviewUserPopup();
  };
  const openRetryPopup = () => {
    closePreviewUserPopup();
    openAddHcpPopup();
  };
  const showSuccessMsg = () => {
    closePreviewUserPopup();
    openSuccess();
    //refresh both the csv links
  };
  return (
    <>
      <Grid onClick={openIframePopup} style={{ color: "red;" }}>
        <Filter
          name="Add your customised filter"
          color={props.targetListCsvProcessingUrl ? "#2D9DE2" : " #969696"}
          bgColor="#F5FFFF"
          icon={
            <AddIcon
              style={
                props.targetListCsvProcessingUrl
                  ? { color: "#2D9DE2" }
                  : { color: "#969696" }
              }
            />
          }
          isPointer={props.targetListCsvProcessingUrl ? true : false}
        />
      </Grid>
      <Grid
        onClick={props.targetListCsvProcessingUrl ? openAddHcpPopup : undefined}
      >
        <Filter
          name="Add HCPs to the target list"
          color={props.targetListCsvProcessingUrl ? "#000000" : "#969696"}
          bgColor="#E1F8F7"
          isPointer={props.targetListCsvProcessingUrl ? true : false}
        />
      </Grid>
      <Grid
      // onClick={props.targetListCsvProcessingUrl ? openAddHcpPopup : undefined}
      >
        <Filter
          name="Remove HCPs to the target list"
          color={props.targetListCsvProcessingUrl ? "#000000" : "#969696"}
          bgColor="#F8ECE1"
          isPointer={props.targetListCsvProcessingUrl ? true : false}
        />
      </Grid>

      <Popup
        open={openPopup}
        headerIcon="icon-popup-info"
        class="send-sfmc-popup-wrapper"
        handleClose={closeIframePopup}
      >
        <Grid item>
          <iframe
            title={"Csv url"}
            height="1058"
            // src={`https://dev-admin.precisionai-portal.bayer.com:8093/?method=post&bucketname=pai-backend-api-csv-development&filename=targets_details/${customUrl}`}
            src={`${process.env.REACT_APP_CUSTOM_FILTER_URL}?method=post&bucketname=${process.env.REACT_APP_AWS_BUCKET}&filename=targets_details/${customUrl}`}
            width="100%"
            style={{ border: "none", overflow: "hidden" }}
          />
        </Grid>
      </Popup>
      <Popup
        open={openUploadPopup}
        title={t("custom_file_upload_title")}
        headerIcon="icon-file-upload"
        class="user-file-popup"
        handleClose={closeUploadPopup}
      >
        <Grid item>
          <CustomFileDropzone
            //  fileType={[".csv", ".xlsx"]}
            source={"home"}
            onValidateFile={ValidateFile}
            // getFileName={(fileName: any) => setFileName(fileName)}
          ></CustomFileDropzone>
        </Grid>
      </Popup>
      <Popup
        open={openPreviewPopup}
        title="Added HCPs validation"
        headerIcon="icon-file-upload"
        parentClass="preview-table-container"
        handleClose={closePreviewUserPopup}
      >
        <CustomPreview onRetry={openRetryPopup} onSuccess={showSuccessMsg} />
      </Popup>
      <Popup
        open={openSuccessPopup}
        handleClose={closeSuccess}
        title={t("hcps_added")}
        maxWidth="md"
        class="save-target-popup"
      >
        <Grid
          container
          justifyContent="flex-start"
          alignItems="flex-start"
          className="save-success-popup"
          direction="column"
        >
          <Grid item className="target-success-icon">
            <ImageIcon classes="icon-target-success"></ImageIcon>
          </Grid>
        </Grid>
      </Popup>
    </>
  );
};

export default CustomFiltersContainer;
