import React, { useState, useEffect } from "react";
import { ImageIcon } from "../ImageIcon";
import { Grid } from "@mui/material";
import "./TablePagination.scss";

export function TablePagination(props: any) {
  const [pageNumber, setPageNumber] = useState<any>(1);
  const [size, setSize] = useState<any>(10);
  const [totalCount, setTotalCount] = useState<any>(10);
  const [currentPageCount, setCurrentPageCount] = useState<any>(10);
  const [totalPages, setTotalPages] = useState<any>(1);

  useEffect(() => {
    setPageNumber(props.pageNumber);
    setSize(props.size);
    setTotalCount(props.totalCount);
    setCurrentPageCount(props.currentPageCount);
    setTotalPages(props.totalPages);
  }, [
    props.currentPageCount,
    props.pageNumber,
    props.size,
    props.totalCount,
    props.totalPages,
  ]);
  return (
    <Grid container className="tble-pgntn-container">
      <div
        className={`img-container img-left-container ${
          pageNumber > 1 ? "active" : ""
        }`}
        onClick={pageNumber > 1 ? props.prevIconClick : undefined}
      >
        <ImageIcon classes="icon-left-arrow"></ImageIcon>
      </div>
      <div className="text-wrapper">
        {currentPageCount !== 0 ? (pageNumber - 1) * size + 1 : 0}-
        {(pageNumber - 1) * size + currentPageCount} of {totalCount}
      </div>
      <div
        className={`img-container img-right-container ${
          pageNumber < totalPages ? "active" : ""
        }`}
        onClick={pageNumber < totalPages ? props.nextIconClick : undefined}
      >
        <ImageIcon classes="icon-right-arrow"></ImageIcon>
      </div>
    </Grid>
  );
}
